import BackupIcon from "@mui/icons-material/Backup";
import UserIcon from "@mui/icons-material/People";
import SettingIcon from "@mui/icons-material/Settings";
// import TemplateIcon from "@mui/icons-material/TextFields";
import LogIcon from "@mui/icons-material/ViewHeadline";

import polyglotI18nProvider from "ra-i18n-polyglot";
import koreanMessages from "ra-language-korean";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { Route } from "react-router-dom";
import Dashboard from "./dashboard";
import Layout from "./layout/Layout";
import ConfigurationPage from "./pages/ConfigurationPage";
import LoginPage from "./pages/LoginPage";
import { BackupList } from "./routes/backups";
import { LogEdit, LogList } from "./routes/logs";
import { SettingEdit, SettingList } from "./routes/settings";
// import { TemplateEdit, TemplateList } from "./routes/templates";
import { UserCreate, UserEdit, UserList } from "./routes/users";
import authProvider from "./utils/authProvider";
import dataProvider from "./utils/dataProvider";

const messages = {
  kr: {
    ra: {
      ...koreanMessages.ra,
      page: {
        ...koreanMessages.ra.page,
        empty: "아무것도 없습니다",
        invite: "생성을 눌러서 만드실 수 있습니다",
      },
      auth: {
        auth_check_error: "권한이 없습니다",
        logout: "로그아웃",
      },
    },
  },
};

const i18nProvider = polyglotI18nProvider(
  // @ts-ignore
  (locale: any) => messages[locale],
  "kr",
  {
    allowMissing: true,
  }
);

function App() {
  return (
    <Admin
      title=""
      loginPage={LoginPage}
      layout={Layout}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
    >
      {(role) => {
        return [
          <CustomRoutes>
            <Route path="/configuration" element={<ConfigurationPage />} />
          </CustomRoutes>,
          <Resource
            name="users"
            list={UserList}
            edit={UserEdit}
            create={UserCreate}
            options={{ label: "회원" }}
            icon={UserIcon}
          />,

          <Resource
            name="settings"
            list={SettingList}
            edit={SettingEdit}
            options={{ label: "설정" }}
            icon={SettingIcon}
          />,
          <Resource
            name="logs"
            list={LogList}
            edit={LogEdit}
            options={{ label: "로그" }}
            icon={LogIcon}
          />,
          <Resource
            name="backups"
            list={BackupList}
            options={{ label: "백업" }}
            icon={BackupIcon}
          />,
        ];
      }}
    </Admin>
  );
}

export default App;
