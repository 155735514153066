import axios, { AxiosRequestConfig } from "axios";
import applyAppTokenRefreshInterceptor from "./refreshToken";
import packageInfo from "../../package.json";

export type ApiError = {
  status: number;
  message: string;
  field?: string;
  details?: string;
};

export const Api = axios.create({
  timeout: 6000,
  // withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,
});

applyAppTokenRefreshInterceptor(Api);

Api.interceptors.request.use((config: AxiosRequestConfig) => {
  const accessToken = localStorage.getItem("accessToken");

  if (config.headers) {
    config.headers.Authorization = `Bearer ${accessToken}`;
    config.headers.app = "admin";
    config.headers.version = packageInfo.version;
  }

  return config;
});
