import * as React from "react";
import { Layout, LayoutProps } from "react-admin";
// import { __prod__ } from "../constants";
import AppBar from "./AppBar";
// import { darkTheme, lightTheme } from "./themes";

const MyLayout = (props: LayoutProps) => {
  return (
    <Layout
      {...props}
      appBar={AppBar}
      // theme={__prod__ ? lightTheme : darkTheme}
    />
  );
};

export default MyLayout;
