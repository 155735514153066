import { useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

const Dashboard = () => {
  const {
    unityProvider,
    UNSAFE__detachAndUnloadImmediate: detachAndUnloadImmediate,
  } = useUnityContext({
    loaderUrl: "game/Build/game.loader.js",
    dataUrl: "game/Build/game.data",
    frameworkUrl: "game/Build/game.framework.js",
    codeUrl: "game/Build/game.wasm",
  });

  useEffect(() => {
    return () => {
      detachAndUnloadImmediate().catch((reason) => {});
    };
  }, [detachAndUnloadImmediate]);

  // We'll round the loading progression to a whole number to represent the
  // percentage of the Unity Application that has loaded.

  return (
    <Unity unityProvider={unityProvider} style={{ width: 1280, height: 720 }} />
  );
};

export default Dashboard;
