import { useQuery } from "react-query";
import { Api, ApiError } from "./api";

// const STALETIME_LONG = 1000 * 60 * 60; // 1h
const STALETIME = 1000 * 60 * 60;

export function useAppQuery() {
  return useQuery<any, ApiError, any>("app", () => Api.get("/app"), {
    staleTime: STALETIME,
  });
}
