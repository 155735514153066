import SettingsIcon from "@mui/icons-material/Settings";
import * as React from "react";
import { forwardRef } from "react";
import { AppBar, Logout, MenuItemLink, UserMenu } from "react-admin";
import { Typography, Box } from "@mui/material";

const styles = {
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
};

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
  return (
    <MenuItemLink
      ref={ref}
      to="/configuration"
      primaryText="관리자 설정"
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
      sidebarIsOpen
    />
  );
});

const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
    <Logout />
  </UserMenu>
);

const CustomAppBar = (props: any) => {
  return (
    <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        sx={styles.title}
        id="react-admin-title"
      />
      <Box component="span" sx={styles.spacer} />
    </AppBar>
  );
};

export default CustomAppBar;
