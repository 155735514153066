import {
  Datagrid,
  DateField,
  Edit,
  List,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import Section from "../components/Section";

export const SettingList = () => (
  <List sort={{ field: "createdAt", order: "DESC" }} exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <DateField label="수정날짜" source="updatedAt" showTime={true} />
    </Datagrid>
  </List>
);

//
// Edit
const SettingTitle = () => {
  return <span>설정</span>;
};

const SettingEditToolbar = () => {
  return (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );
};

export const SettingEdit = () => (
  <Edit title={<SettingTitle />}>
    <SimpleForm toolbar={<SettingEditToolbar />}>
      <Section title="시스템" />

      <TextInput
        label="전자우편"
        source="email"
        fullWidth
        helperText="피드백 수신이메일"
      />

      <TextInput
        label="버전"
        source="version"
        fullWidth
        helperText="스토어에 등록된 버전으로 이것보다 낮은 앱은 최신버전이 아니라고 표시됨"
      />
      {/* <TextInput label="App store(iOS) URL" source="appIosUrl" fullWidth />
      <TextInput
        label="Play store(안드로이드) URL"
        source="appAndroidUrl"
        fullWidth
      /> */}

      {/* <Section title="회사" />

      <TextInput label="통신판매번호" source="company.tongsinNo" fullWidth />
      <TextInput label="사업자등록번호" source="company.bizNum" fullWidth />
      <TextInput label="상호" source="company.name" fullWidth />
      <TextInput label="대표자명" source="company.owner" fullWidth />
      <TextInput label="사업장소재지" source="company.address" fullWidth />
      <TextInput label="메일문의" source="company.email" fullWidth />
      <TextInput label="고객센터" source="company.phone" fullWidth /> */}
    </SimpleForm>
  </Edit>
);
