import { useState } from "react";
import {
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  email,
  Labeled,
  List,
  Pagination,
  ReferenceManyField,
  required,
  SaveButton,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useRecordContext,
} from "react-admin";
// import Thumbnail from "../components/Thumbnail";
import {
  Box,
  Button,
  CircularProgress,
  TextField as MTextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { IUser, RoleType, RoleTypeNames } from "../common/types";
import { Api } from "../utils/api";

export const UserList = () => (
  <List sort={{ field: "createdAt", order: "DESC" }} exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="ID" source="username" />
      <TextField label="이름" source="name" />
      <SelectField
        label="역할"
        source="role"
        choices={Object.values(RoleType).map((value) => ({
          id: value,
          name: RoleTypeNames[value],
        }))}
      />
      <DateField label="가입일" source="createdAt" showTime={true} />
      <DateField label="접속일" source="accessedAt" showTime={true} />
    </Datagrid>
  </List>
);

const UserTitle = () => {
  const record = useRecordContext<IUser>();
  return <span>회원 {record ? `"${record.name}"` : ""}</span>;
};

const UserEditToolbar = () => {
  return (
    <Toolbar>
      <SaveButton />
      <DeleteButton />
    </Toolbar>
  );
};

const SendMessageField = () => {
  type FormData = {
    message: string;
  };
  const record = useRecordContext<IUser>();
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    if (data.message === "") return;
    setLoading(true);
    try {
      await Api.post("/send-message", { text: data.message, user: record.id });
      alert("전송하였습니다.");
    } catch (err) {
      // @ts-ignore
      alert(err["message"]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginBottom: 10,
      }}
    >
      <Controller
        control={control}
        name="message"
        defaultValue=""
        rules={{
          required: "필수 항목입니다.",
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <MTextField
            sx={{ width: 500 }}
            placeholder="메시지"
            onBlur={onBlur}
            value={value}
            onChange={onChange}
          />
        )}
      />

      <Button
        variant="text"
        disabled={loading}
        sx={{ width: 150 }}
        onClick={handleSubmit(onSubmit)}
      >
        {loading && <CircularProgress size={18} thickness={2} />}
        메시지 전송
      </Button>
    </Box>
  );
};

export const UserEdit = () => {
  const { id } = useParams<"id">();

  // const appRes = useAppQuery();

  return (
    <Edit title={<UserTitle />}>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <Labeled sx={{ marginBottom: 4 }}>
          <DateField source="createdAt" label="가입일" showTime={true} />
        </Labeled>

        <Labeled sx={{ marginBottom: 4 }}>
          <TextField label="ID" source="username" fullWidth />
        </Labeled>

        <SendMessageField />

        <Labeled sx={{ marginBottom: 4 }}>
          <ReferenceManyField
            label="접속 디바이스"
            target="user"
            reference="devices"
            sortable={false}
            filter={{ user: id }}
            pagination={<Pagination />}
            fullWidth
          >
            <Datagrid>
              <TextField label="app" source="app" />
              <TextField label="version" source="version" />
              <TextField label="ip" source="ip" />
              <TextField label="browser" source="browser" />
              <TextField label="os" source="os" />
              <TextField
                label="pushToken"
                source="pushToken"
                // cellClassName={classes.comment}
              />
              <TextField label="deviceId" source="deviceId" />
              <TextField label="언어" source="language" />
              <DateField
                label="토큰갱신일"
                source="updatedAt"
                showTime={true}
              />
              <DateField
                label="토큰만료일"
                source="expiresAt"
                showTime={true}
              />
            </Datagrid>
          </ReferenceManyField>
        </Labeled>
      </SimpleForm>
    </Edit>
  );
};

export const UserCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <TextInput
          label="Email"
          source="username"
          validate={[required(), email()]}
          fullWidth
        />

        <SelectInput
          label="역할"
          source="role"
          validate={[required()]}
          choices={[
            {
              id: RoleType.ADMIN,
              name: RoleTypeNames[RoleType.ADMIN],
              disabled: false,
            },
            {
              id: RoleType.USER,
              name: RoleTypeNames[RoleType.USER],
              disabled: true,
            },
          ]}
        />

        <TextInput
          label="패스워드"
          source="password"
          validate={[required()]}
          fullWidth
        />

        <TextInput
          label="이름"
          source="firstName"
          validate={[required()]}
          fullWidth
        />

        <TextInput
          label="성"
          source="lastName"
          validate={[required()]}
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};
