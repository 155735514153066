import { ID, RoleType } from ".";

export enum AuthProvider {
  EMAIL = "email",
}

export const AuthProviderNames = {
  email: "이메일",
};

export enum UserState {
  JOINED = "joined",
  LEFT = "left",
}

export const UserStateNames = {
  joined: "가입",
  left: "탈퇴",
};

export interface IUser {
  id: ID;

  createdAt: Date;
  updatedAt: Date;
  accessedAt: Date;

  leftAt?: Date;

  state: UserState;
  role: RoleType;

  authProvider?: AuthProvider;

  username: string;
  password: string;
  name: string;

  email?: string;
}
