import { stringify } from "query-string";
import {
  CreateParams,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams,
} from "react-admin";
import { Api } from "./api";
import { uploadSound } from "./imageUpload";

const getList = async (resource: string, params: GetListParams) => {
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;
  const from = (page - 1) * perPage;
  const to = page * perPage - 1;
  const query = {
    offset: from,
    limit: to - from + 1,
    sort: (order === "DESC" ? "-" : "") + field,
    filter: JSON.stringify(params.filter),
  };

  return Api.get<any, any>(`${resource}?${stringify(query)}`);
};

const getOne = async (resource: string, params: GetOneParams) => {
  const data = await Api.get<any, any>(`${resource}/${params.id}`);
  return { data };
};

const getMany = async (resource: string, params: GetManyParams) =>
  Api.get<any, any>(
    `${resource}?${stringify({
      filter: JSON.stringify({ id: params.ids }),
    })}`
  );

const getManyReference = async (
  resource: string,
  params: GetManyReferenceParams
) => {
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;
  const from = (page - 1) * perPage;
  const to = page * perPage - 1;
  const query = {
    offset: from,
    limit: to - from + 1,
    sort: (order === "DESC" ? "-" : "") + field,
    filter: JSON.stringify(params.filter),
    ref: true,
  };

  return Api.get<any, any>(`${resource}?${stringify(query)}`);
};

const update = async (resource: string, params: UpdateParams) => {
  switch (resource) {
    case "bot-messages":
      params.data = await uploadSound(params.data, "voice");
      break;
    default:
      break;
  }

  const data = await Api.put<any, any>(`${resource}/${params.id}`, params.data);

  return { data };
};

const updateMany = (resource: string, params: UpdateManyParams) =>
  Api.put<any, any>(
    `${resource}?${stringify({
      filter: JSON.stringify({ id: params.ids }),
    })}`,
    params.data
  );

const create = (resource: string, params: CreateParams) =>
  Api.post<any, any>(`${resource}`, params.data).then((data) => ({ data }));

const deleteFn = (resource: string, params: DeleteParams) =>
  Api.delete<any, any>(`${resource}/${params.id}`).then((data) => ({ data }));

const deleteMany = (resource: string, params: DeleteManyParams) =>
  Api.delete<any, any>(resource, {
    data: {
      ids: params.ids,
    },
  }).then((data) => ({ data }));

const functions = {
  getList,
  getOne,
  getMany,
  getManyReference,
  update,
  updateMany,
  create,
  delete: deleteFn,
  deleteMany,
};

export default functions;
