import { ID } from ".";

export enum CookingType {
  BASIC = "basic",
  NORMAL = "normal",
  SECRET = "secret",
}
export const CookingTypeNames = {
  basic: "기본",
  normal: "일반",
  secret: "씨크릿",
};

export interface ICooking {
  id: ID;

  tag: string;
  type: CookingType;

  ingredients: ID[];
  ingredientTags: string[];

  ingredientCounts: number[];
  sellPrice: number;
  purchasePrice: number;

  name: string;
  text: string;
}
