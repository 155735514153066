import Resizer from "react-image-file-resizer";
import { Api } from "./api";
import axios from "axios";
import { getFileName } from "./stringUtils";

export type ImageType = "PROFILE" | "PHOTO" | "SOUND";

const resizeFile = (file: File, size: number) =>
  new Promise((resolve) => {
    //
    // jimp.scaleToFit 와 같다.
    Resizer.imageFileResizer(
      file,
      size,
      size,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob",
      size < 1440 ? size : undefined, // 썸네일인 경우 최소사이즈를 기준으로 한다.
      size < 1440 ? size : undefined
    );
  });

/**
 *
 * @param file
 * @param type
 * @returns key
 */
export const uploadImageAsync = async (file: File, type: ImageType) => {
  const response = (await Api.post("/signed-url", {
    type,
  })) as any;

  for (const info of response) {
    const image = await resizeFile(file, info.size);
    // 파일을 업로드합니다.
    await axios.put(info.url, image, {
      headers: {
        "Content-Type": "image/jpeg",
      },
    });
  }

  return response[0].key;
};

const uploadSoundAsync = async (file: File, key: string) => {
  const response = (await Api.post("/signed-url", {
    type: "SOUND",
    key,
  })) as any;

  await axios.put(response.url, file, {
    headers: {
      "Content-Type": "audio/mpeg",
    },
  });

  return response.key;
};

export const uploadSound = async (data: any, path: string) => {
  if (!data[path]) return data;

  const isNewSound = data[path].rawFile instanceof File;

  if (isNewSound) {
    data[path] = await uploadSoundAsync(data[path].rawFile as any, data["no"]);
  } else {
    data[path] = getFileName(data[path]);
  }

  return data;
};
