// import mongoose from "mongoose";
// export type ID = mongoose.Schema.Types.ObjectId;
export type ID = string;

//
// User
export enum RoleType {
  ADMIN = "admin",
  USER = "user",
}

export const RoleTypeNames = {
  admin: "어드민",
  user: "일반회원",
};

export type AuthUser = {
  id: ID;
  role: RoleType;
  username: string;
};

export interface ActionLog {
  userId: ID;
  date: Date;
}

export * from "./Device.type";
export * from "./User.type";
export * from "./Policy.type";
export * from "./Setting.type";
export * from "./Template.type";
export * from "./Report.type";
export * from "./Ingredient.type";
export * from "./Cooking.type";
export * from "./Customer.type";
export * from "./Shop.type";
export * from "./Stage.type";
export * from "./Map.type";
export * from "./StageRecord.type";
