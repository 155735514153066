import { ID } from ".";

export enum TemplateTag {
  //
  // Email
  TEMPORARY_PASSWORD = "temporary-password",
}
export const TemplateTagNames = {
  "temporary-password": "임시비밀번호 발급알림",
};

export enum TemplateType {
  EMAIL = "email",
}

export const TemplateTypeNames = {
  email: "이메일",
};

export interface ITemplate {
  id: ID;

  type: TemplateType;
  tag: TemplateTag;

  title: string;
  text: string;
}
