import { ID } from ".";

export enum PolicyType {
  TOS = "tos",
  PRIVACY = "privacy",
  TOS_EN = "tos_en",
  PRIVACY_EN = "privacy_en",
}

export enum PolicyTypeNames {
  tos = "이용약관",
  privacy = "개인정보 처리방침",
  tos_en = "Terms of service",
  privacy_en = "Privacy Policy",
}

export interface IPolicy {
  id: ID;
  updatedAt: Date;
  type: PolicyType;
  text: string;
}
